@import '../../styles/fonts';
@import '../../module/animation/animation';

.home-news-item{
  box-sizing: border-box;
  overflow: hidden;

  &-image{
    width: 100%;
    height: 180px;
    border-radius: 8px;
    object-fit: cover;
    @include medium{
      height: 211px;
    }
  }

  &-content{
    padding: 0 10px;
    @include medium{
      padding: 0 20px;
    }
  }

  &-category{
    width: fit-content;
    margin-top: 8px;
    padding: 6px 13px;
    border-radius: 8px;
    @include font-H5;
    color: white;
    background-color: rgba($color: #EBF0F0, $alpha: 0.1);
    @include medium{
      margin-top: 24px;
    }
  }

  &-title{
    display: -webkit-box;
    width: calc(100% );
    margin-top: 8px;
    @include font-H4;
    color: white;
    text-align: left;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    @include medium{
      margin-top: 24px;
    }
  }

  &-hashtag-container{
    display: flex;
    margin-top: 6px;
    @include medium{
      margin-top: 12px;
    }
  }

  &-hashtag{
    margin-right: 10px;
    @include font-H5;
    color: #0094FF;
    
    &:last-child{
      margin-right: 0;
    }
  }
}
