@import '../../styles/fonts';

.employment-recruit-section-1 {
  margin-top: 90px;
  @include medium {
    margin-top: 130px;
  }
  @include extra-large {
    margin-top: 174px;
  }

  &-content {
    &-wrapper{
      display: flex;

    }
    &-item {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 15px 30px;
      margin-bottom: 20px;
      border: 1px solid #2D3032;
      border-radius: 8px;
      &.mobile{
        cursor: pointer;
      }
      @include medium {
        flex-direction: row;
        align-items: center;
        padding: 15px 20px;
      }
      @include extra-large {
        flex-direction: row;
        align-items: center;
        padding: 15px 20px;
      }
      transition: background-color 0.4s;

      &:hover {
        background: #171B21;
      }

      &-name {
        display: block;
        align-items: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 310px;
        height: 45px;
        color: #fff;
        @include font-H3-bold;
        @include medium {
          display: flex;
          align-items: center;
          width: 536px;
        }
        @include extra-large {
        }
      }

      &-job {
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 310px;
        @include font-H5;
        color: #fff;
      }

      &-join {
        display: flex;
        flex: 1;
        justify-content: flex-end;

        &-button {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 114px;
          height: 50px;
          border-radius: 12px;
          background-color: #253BFF;
          @include font-button;
          color: #fff;
          transition: background-color 0.4s;

          &:hover{
            background-color: #162DF3;
          }

        }
      }
    }
  }

}
