@import "./animationKeyframes";

@mixin transform-translate-50 {
  transform: translate(calc((var(--mouse-x, 0) - 0.5) * 50px), calc((var(--mouse-y, 0) - 0.5) * 50px))
}
@mixin transform-translate-30 {
  transform: translate(calc((var(--mouse-x, 0) - 0.5) * 30px), calc((var(--mouse-y, 0) - 0.5) * 30px))
}
@mixin transform-translate-25 {
  transform: translate(calc((var(--mouse-x, 0) - 0.5) * 25px), calc((var(--mouse-y, 0) - 0.5) * 25px))
}
@mixin transform-translate-12 {
  transform: translate(calc((var(--mouse-x, 0) - 0.5) * 12px), calc((var(--mouse-y, 0) - 0.5) * 12px))
}

@mixin fade-in-from-bottom-paused () {
  animation-name: fade-in, slide-from-bottom;
  animation-duration: 200ms, 300ms;
  animation-timing-function: ease-in, ease-in;
  animation-play-state: paused;
}

@mixin fade-in-from-bottom ($delay: 0s) {
  animation-name: fade-in, slide-from-bottom;
  animation-duration: 200ms, 300ms;
  animation-timing-function: ease-in, ease-in;
  animation-delay: $delay;
}
