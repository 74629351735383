@import "../../styles/fonts";
@import "../../module/animation/animation";
@import "../../module/animation/animation";

.community-section-2 {
  margin-top: 512px;
  @include fade-in-from-bottom-paused;
  @include medium {
    margin-top: 500px;
  }
  @include extra-large {
    margin-top: 196px;
  }
  &-title {
    margin-bottom: 23px;
    @include font-H3-bold;
    @include medium {
      margin-bottom: 30px;
      @include font-H2;
    }
    @include extra-large {
      margin-bottom: 36px;
      @include font-H1;
    }
  }
  &-sub-title {
    margin-bottom: 54px;
    @include font-H4;
    @include medium {
      margin-bottom: 70px;
    }
    @include extra-large {
      margin-bottom: 134px;
      @include font-H3;
    }
  }
  &-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    @include medium {
      flex-direction: row;
    }
    &-card {
      position: relative;
      &:first-child {
        margin-bottom: 10px;
        @include medium {
          margin-bottom: 0;
        }
      }
      @include medium {
        margin-right: 18px;
      }
      @include extra-large {
        margin-right: 40px;
      }
      &-top {
        position: absolute;
        width: 368px;
        height: 124px;
        @include extra-large {
          width: 420px;
        }
      }
      &-content {
        height: 258px;
        margin-top: 124px;
        background: #222325;
        border-radius: 0px 0px 24px 24px;
        width: 368px;
        padding: 0 20px 40px 20px;
        @include extra-large {
          width: 420px;
          padding: 0 40px 40px 40px;
        }
        &-title {
          height: 35px;
          object-fit: contain;
          margin-bottom: 16px;
        }
        &-description {
          margin-bottom: 55px;
          @include font-H4;
        }
        &-link {
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          &-text {
            @include font-H4;
            color: #6f5beb;
          }
          &-button {
            width: 26px;
            height: 11px;
          }
        }
      }
    }
  }
}
.orange {
  color: #ebb15b;
}
