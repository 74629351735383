@import "variable";

@mixin container-small {
  width: $small-container;
  height: fit-content;
  margin: 0 auto;
}

@mixin container-medium {
  width: $medium-container;
  height: fit-content;
  margin: 0 auto;
}

@mixin container-large {
  width: $large-container;
  height: fit-content;
  margin: 0 auto;
}

@mixin container-extra-large {
  width: $extra-large-container;
  height: fit-content;
  margin: 0 auto;
}

@mixin small {
  @media only screen and (min-width: 0) {
    @content;
  }
}

@mixin medium {
  @media only screen and (min-width: $medium-screen) {
    @content;
  }
}

@mixin large {
  @media only screen and (min-width: $large-screen) {
    @content;
  }
}

@mixin extra-large {
  @media only screen and (min-width: $extra-large-screen) {
    @content;
  }
}

@mixin hover-button {
  padding: 10px 16px;
  border-radius: 8px;
  transition: background-color 0.4s;
  &:hover {
    // padding: 15px 25px;
    // border-radius: 20px;
    background-color: rgba($color: #f1f4fa, $alpha: 0.2);
  }
}

@mixin fade-in-from-bottom($delay: 0s) {
  animation-name: fade-in, slide-from-bottom;
  animation-duration: 200ms, 300ms;
  animation-timing-function: ease-in, ease-in;
  animation-delay: $delay;
}

@mixin disable-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
}
