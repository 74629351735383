@import "../../styles/common";
@import "../../styles/fonts";
@import "../../styles/mixins";
@import '../../module/animation/animation';

.introduction-section-4 {
  height: 388px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 60px 20px;
  position: relative;
  border-radius: 24px;
  overflow: hidden;
  margin-top: 100px;
@include fade-in-from-bottom-paused;
  &::before {
    content: "";
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(../../assets/image/introduction-section-4/introductionBackground.png);
    background-repeat: no-repeat;
    background-position: right -350px top 0;
    background-size: cover;
    @include medium{
      background-position: center;
    }
  }

  .box-title {
    position: relative;
    z-index: 1;
    text-align: center;
    @include font-H3;
  }

  .box-text {
    position: relative;
    z-index: 1;
    margin-top: 40px;
    text-align: center;
    @include font-H4-bold;
    @include medium{
      padding: 0 40px;
      @include font-H3-bold;
    }
    @include extra-large{
      padding: 0;
      @include font-H2;
    }
  }
}
