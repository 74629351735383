@import "../../styles/common";
@import "../../styles/fonts";
@import "../../styles/mixins";
@import '../../module/animation/animation';

.team-culture-section-3{
  margin-top: 100px;
  @include fade-in-from-bottom-paused;
  .title{
    margin-bottom: 70px;
    @include font-H2-mobile;
    @include medium{
      width: 458px;
      @include font-H1-tablet;
      margin-bottom: 111px;
    }
    @include extra-large{
      width: 662px;
      @include font-H1;
    }
  }
  .desc-container{
    @include extra-large{
      display: flex;
      justify-content: space-between;
    }
    .desc-wrapper{
      margin-bottom: 72px;
      @include medium{
        width: 400px;
      }
      @include extra-large{
        width: 330px;
      }
      &:last-child{
        margin-bottom: 0;
      }
      .desc-title{
        margin-bottom: 26px;
        @include font-H3-bold;
        @include medium{
          @include font-H2-mobile;
          margin-bottom: 20px;
        }
        @include extra-large{
          @include font-H3;
          margin-bottom: 20px;
        }
      }
      .desc{
        @include font-H4;
      }
    }
  }
}
