@import "../../styles/common";
@import "../../styles/fonts";
@import "../../styles/mixins";
@import '../../module/animation/animation';

.team-culture-section-2{
  display: flex;
  align-items: center;
  height: 526px;
  margin-top: 190px;
  background: #171B21;
  @include fade-in-from-bottom-paused;
  @include medium{
    height: 410px;
  }
  .content-wrapper{
    display: flex;
    align-items: center;
    .introduction-swiper{
      display: flex;
      .swiper-content{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 40px;
        .introduction-img{
          width: 82px;
          height: 82px;
          border-radius: 50%;
          object-fit: cover;
        }
        .introduction-text{
          margin-top: 48px;
          text-align: center;
          @include font-H4;
        }
        .introduction-writer{
          margin-top: 48px;
          @include font-H5;
        }
      }
    }
    .swiper-arrow-wrapper{
      display: none;
      @include medium{
        visibility: visible;
        display: flex;
        align-items: center;
        &-left{
          justify-content: flex-end;
        }
        &-right{
          justify-content: flex-start;
        }
        .swiper-arrow {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          .icon{
            width: 26px;
            object-fit: contain;
          }
        }
      }
    }
    .swiper-pagination-bullet{
      background-color: #fff;
      &.swiper-pagination-bullet-active{
        background-color: #253BFF;
      }
    }
  }
}
