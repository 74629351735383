@import "../styles/fonts";
@import "../module/animation/animation";
@keyframes arrow-move {
  0% {
  }
  100% {
    transform: translateX(14px);
  }
}
.link-button {
  display: flex;
  align-items: center;
  gap: 8px;
  @include font-H4;
  color: white;
  img {
    width: 26px;
    height: 26px;
    // transform: translateX(8px);
    object-fit: contain;
  }
  &:hover img {
    // animation: arrow-move 1.5s linear infinite;
  }
}
