@import "../styles/fonts";
@import "../module/animation/animation";

.footer {
  margin-top: 97px;
  margin-bottom: 60px;
  @include small {
    margin-top: 97px;
  }
  @include medium {
    margin-top: 150px;
  }
  @include extra-large {
    margin-top: 324px;
  }

  &-logo {
    width: 107px;
    object-fit: contain;
  }

  &-container {
    display: flex;
    flex-direction: column;
    margin-top: 45px;
    @include medium {
      flex-direction: row;
      margin-top: 0;
    }
    @include extra-large {
    }
  }

  &-links {
    display: flex;
    align-items: center;
    margin-top: 63px;
    column-gap: 21px;
    &-icon {
      width: 44px;
      height: 44px;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    // border-bottom: solid 1px #222222;

    @include medium {
      width: 200px;
      margin-top: 62px;
      margin-right: 10px;
    }

    &:last-child {
      margin-right: 0;
      border-bottom: none;
    }

    a {
      width: fit-content;
      padding: 15px 0 15px 30px;
      @include font-H4;
      text-decoration: none;
      color: white;
      &:hover {
        border-bottom: 1px #f1f4fa solid;
      }
      @include medium {
        padding: 8px 0;
      }
    }
    .main {
      padding: 15px 0;
      border-bottom: solid 1px #3f4553;
      @include medium {
        padding: 0;
        margin-bottom: 36px;
        border-bottom: none;
      }
    }
  }

  &-bottom {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
    &.address{
      margin-top: 8px;
      margin-bottom: 20px;
    }
  }
  .footer-bottom-wrapper{
    display: flex;
    flex-direction: column;
    margin-top: 227px;
  }
}
