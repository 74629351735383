@import "../../styles/common";
@import "../../styles/fonts";
@import "../../styles/mixins";
@import '../../module/animation/animation';
.introduction-section-3{
  margin-top: 100px;
  @include fade-in-from-bottom-paused;
  &-youtube{
    //반응형 margin-top 확인 필요
    margin-top: 130px;
    border-radius: 13px;
    overflow: hidden;
    @include medium{
      border-radius: 26px;
    }
    &-content{
      width: 100%;
      height: 100%;
    }
  }
}