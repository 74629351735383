@import "../../styles/fonts";
@import "./../../styles/variable";
@import "../../module/animation/animation";

.service-section-1 {
  margin-top: calc(90px - $header-height);
  @include fade-in-from-bottom;

  @include medium {
    margin-top: calc(130px - $header-height);
  }
  @include extra-large {
    // margin-top: calc(435px - $header-height);
    margin-top: 218px;
  }
  &-title {
    width: 200px;
    @include font-H2;
    padding-top: $header-height;
    margin-bottom: 30px;
    @include medium {
      width: 100%;
    }
    @include extra-large {
      padding-top: 0;
      @include font-H1;
    }
  }
  &-sub-title {
    position: relative;
    @include font-H4-bold;
    color: #b0b8c1;
    @include medium {
      @include font-H4-bold;
    }
    @include extra-large {
      @include font-H3;
    }
  }
  &-img {
    position: relative;
    width: 350px;
    margin-top: 21px;
    @include medium {
      width: 794px;
      margin-top: 28px;
    }
    @include extra-large {
      width: 1200px;
      margin-top: 72px;
    }
    &-1 {
      width: 100%;
      object-fit: contain;
    }
  }
  &-img-2 {
    position: absolute;
    top: 147px;
    left: 39.5%;
    width: 223px;
    object-fit: contain;
    z-index: 99;
    @include medium {
      top: 328px;
      left: 29%;
      width: 576px;
    }
    @include extra-large {
      top: 466px;
      left: 50%;
      width: 600px;
    }
  }
  &-text {
    width: 350px;
    margin-top: 204px;
    @include font-H3-bold;
    line-height: 38px;
    color: #838990;
    @include medium {
      width: 794px;
      margin-top: 421px;
      @include font-H3-bold;
      line-height: 34px;
    }
    @include extra-large {
      width: 485px;
      margin-top: 0;
    }
  }
  &-hilight-text {
    color: #fff;
  }
  &-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 209px;
    height: 50px;
    margin-top: 46px;
    background: #253bff;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.4s;
    &:hover {
      background-color: #162df3;
    }
  }
}
