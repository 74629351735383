.tilt-component{
  overflow: hidden;
  position: relative;
  
  &-container{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  &-hover-gradient{
    position: absolute;
    width: 50%;
    height: 50%;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.2;
  }
}

