// @import "../../styles/common";
// @import "../../styles/fonts";
// @import "../../styles/mixins";
@import "../../module/animation/animation";
// @keyframes arrow-move {
//   0% {
//     transform: translateX(0px);
//   }
//   100% {
//     transform: translateX(12px);
//   }
// }
.team-culture-section-4 {
  @include fade-in-from-bottom-paused;
  margin-top: 100px;
  // width: 100%;
  // height: 594px;
  // background-image: url(../../assets/image/team-culture-section-4/background.png);
  // background-repeat: no-repeat;
  // background-position: center;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  // @include medium{
  //   height: 751px;
  // }
  // @include extra-large{
  //   height: 753px;
  // }
  // &-link{
  //   transition: background-color 0.4s;
  //   &:hover{
  //     background-color: rgba($color: #EBF0F0, $alpha: 0.1);
  //   }
  // }
  // h1{
  //   width: 279px;
  //   @include font-H2-mobile;
  //   text-align: center;
  //   @include medium{
  //     width: 631px;
  //     @include font-H1;
  //   }
  // }
  // a{
  //   margin-top: 44px;
  //   border: 1px solid #FFFFFF;
  //   border-radius: 8px;
  //   padding: 12px 20px;
  //   color: #fff;
  //   @include font-H4-bold;
  //   display: flex;
  //   align-items: center;
  //   &:hover img{
  //     animation: arrow-move 1.5s linear infinite;

  //   }
  // }
  // img{
  //   width: 28px;
  //   height: 28px;
  //   margin-left: 15px;

  // }
}
