$small-container : 350px;
$medium-container : 794px;
$large-container : 992px;
$extra-large-container : 1200px;

$mobile-header-height: 70px;
$header-height: 90px;

$small-screen : 390px;
$medium-screen : 834px;
$large-screen : 992px;
$extra-large-screen : 1280px;
