@import '../../styles/fonts';
@import '../../module/animation/animation';

.news-content {
  &-arrow{
    width: 8px;
    margin: 0 12px;
    object-fit: contain;
  }
  &-main{
    width: 100%;
    @include extra-large{
      width: 845px;
      margin-right: 117px;
    }

    &-title{
      margin-top: 36px;
      @include font-H3;
      line-height: 30px;
      @include medium{
        @include font-H2;
      }
    }

    &-image{
      width: 100%;
      margin-top: 35px;
      border-radius: 26px;
      object-fit: contain;
      @include extra-large{
        width: 845px;
      }
    }

    &-text{
      margin-top: 72px;
      @include font-H4_2;
    }

    &-shares{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 152px;
    }

    &-share{
      margin-right: 20px;
      &:last-child{margin-right: 0;}
      cursor: pointer;

      &-icon{
        width: 34px;
        height: 34px;
        object-fit: contain;
      }
    }



    &-hashtags{
      display: flex;
      justify-content: center;
      margin-top: 43px;
    }

    &-hashtag{
      margin-right: 10px;
      @include font-H5;
      color: #0094FF;

      &:last-child{
        margin-right: 0;
      }
    }

    &-to-list{
      display: block;
      width: fit-content;
      margin: 72px auto 0;
      padding: 6px 25px;
      background: rgba(235, 240, 240, 0.1);
      mix-blend-mode: normal;
      border-radius: 8px;
      @include font-H5;
      color: white;
      transition: background-color 0.4s;
      &:hover{
        background-color: rgba($color: #fcfefe, $alpha: 0.3);
      }
    }
  }

  &-recent{
    display: none;
    width: 238px;

    @include extra-large{
      display: block;
      position: -webkit-sticky;
      position: sticky;
      top: $header-height;
    }

    &-title{
      @include font-H3;
    }

    &-item{
      display: block;
      width: 100%;
      margin-bottom: 20px;
      padding: 24px;
      border: 1px solid rgba($color: #CFDBD5, $alpha: 0.15);
      border-radius: 15px;
      color: white;
      &:last-child{
        margin-bottom: 0;
      }

      &-image{
        width: 100%;
        border-radius: 12px;
        object-fit: contain;
      }

      &-text{
        margin-top: 20px;
        @include font-H5;
      }
    }


  }

  &-bottom{
    margin-top: 100px;
    border-top: solid 1px #444444;

    &-title{
      margin-top: 87px;
      @include font-H2;
      text-align: center;
    }
  }

  &-relative-container{
    display: grid;
    margin-top: 72px;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: 420px;
    column-gap: 15px;
    row-gap: 20px;


    @include medium{
      grid-template-columns: repeat(2, 1fr);
    }
    @include extra-large{
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
