@import '../../styles/fonts';
@import '../../module/animation/animation';

.tech-section-4{
    margin-top: 153.22px;
    @include medium{
        margin-top: 167px;
    }
    @include extra-large{
        margin-top: 245px;
    }
    @include fade-in-from-bottom-paused;

    &-title{
        @include font-H2-mobile;
        @include medium{
            @include font-H1;

        }
    }

    &-hashtag{
        margin-top: 35px;
        @include font-H3;
        background: #158EFF;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }

    &-grid{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(1, 420px);
        margin-top: 35px;
        row-gap: 24px;
        column-gap: 15px;

        @include medium{
            margin-top: 55px;
            grid-template-columns: repeat(2, 1fr);
        }
        @include extra-large{
            margin-top: 96px;
            grid-template-columns: repeat(3, 1fr);
        }
    }
}
