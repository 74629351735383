@import '../../styles/fonts';
@import '../../module/animation/animation';

$mobileImgWidth: 20px;
$tabletImgWidth: 20px;
$extraLargeImgWidth: 30px;

@keyframes downSmall {
  0% {
    opacity: 0;
    top: 8px;
  }

  5% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    top: 16px;
  }
}

@keyframes downExtraLarge {
  0% {
    opacity: 0;
    top: 8px;
  }

  5% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    top: 38px;
  }
}

.section-down-button {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0px;
  width: 24px;
  height: 58px;

  @include medium {
    bottom: 20px;
  }

  @include extra-large {
    width: 20px;
    height: 80px;
    bottom: 20px;
  }

  img {
    width: $mobileImgWidth;
    object-fit: contain;

    @include extra-large {
      width: $extraLargeImgWidth;
    }
  }

  span {
    position: absolute;
    top: 0;
    right: calc(50% - 11px);
    bottom: 25px;
    left: calc(50% - 11px);
    width: 24px;
    border: 2px solid #fff;
    border-radius: 16px;

    @include medium {
      bottom: 20px;
    }

    @include extra-large {
      right: calc(50% - 15px);
      bottom: 25px;
      left: calc(50% - 15px);
      width: 30px;
    }

    &::before {
      opacity: 0;
      position: absolute;
      top: 8px;
      right: calc(50% - 3px);
      left: calc(50% - 3px);
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #fff;
      content: '';
      animation: downSmall 1.2s linear infinite;

      @include extra-large {
        animation: downExtraLarge 1.2s linear infinite;
      }

    }
  }

}