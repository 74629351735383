@import 'mixins';

@mixin font-face(
  $font-name,
  $file-name,
  $weight: normal,
  $style: normal,
) {
  @font-face {
    font-family: '#{$font-name}';
    src: url('../assets/fonts/#{$file-name}.ttf') format('truetype');
    font-weight: $weight;
    font-style: $style;
  }
}

@include font-face('Axiforma', 'Axiforma-Book', 100, normal);
@include font-face('Axiforma', 'Axiforma-Thin', 200, normal);
@include font-face('Axiforma', 'Axiforma-Light', 300, normal);
@include font-face('Axiforma', 'Axiforma-Medium', 400, normal);
@include font-face('Axiforma', 'Axiforma-Regular', 500, normal);
@include font-face('Axiforma', 'Axiforma-SemiBold', 600, normal);
@include font-face('Axiforma', 'Axiforma-Bold', 700, normal);
@include font-face('Axiforma', 'Axiforma-ExtraBold', 800, normal);
@include font-face('Axiforma', 'Axiforma-Heavy', 900, normal);
@include font-face('LINE-Seed-Sans', 'LINESeedSans_Th', 100, normal);
@include font-face('LINE-Seed-Sans', 'LINESeedSans_Rg', 400, normal);
@include font-face('LINE-Seed-Sans', 'LINESeedSans_Bd', 700, normal);
@include font-face('LINE-Seed-Sans', 'LINESeedSans_He', 900, normal);

@mixin font-size($font-size) {
  font-size: $font-size;
}

@mixin line-height($line-height) {
  line-height: $line-height;
}

@mixin font-H1 {
  font-family: LINE-Seed-Sans, sans-serif;
  font-weight: 700;
  @include font-size(74px);
  @include line-height(100px);
  letter-spacing: -0.02em;
}
@mixin font-H1-tablet {
  font-family: LINE-Seed-Sans, sans-serif;
  font-weight: 700;
  @include font-size(51px);
  @include line-height(69px);
  letter-spacing: -0.02em;
}
@mixin font-H2 {
  font-family: LINE-Seed-Sans, sans-serif;
  font-weight: 700;
  @include font-size(36px);
  @include line-height(48px)
}
@mixin font-H2-mobile {
  font-family: LINE-Seed-Sans, sans-serif;
  font-weight: 700;
  @include font-size(32px);
  @include line-height(42px)
}
@mixin font-H3 {
  font-family: LINE-Seed-Sans, sans-serif;
  font-weight: 700;
  @include font-size(26px);
  @include line-height(48px)
}
@mixin font-H3-bold {
  font-family: LINE-Seed-Sans, sans-serif;
  font-weight: 700;
  @include font-size(26px);
  @include line-height(30px)
}
@mixin font-H4 {
  font-family: LINE-Seed-Sans, sans-serif;
  font-weight: 400;
  @include font-size(18px);
  @include line-height(30px);
  letter-spacing: -0.04em;
}
@mixin font-H4-2 {
  font-family: LINE-Seed-Sans, sans-serif;
  font-weight: 400;
  line-height: 40px;
  @include font-size(18px);
  @include line-height(30px);
  letter-spacing: -0.04em;
}
@mixin font-H4-bold {
  font-family: LINE-Seed-Sans, sans-serif;
  font-weight: 700;
  @include font-size(18px);
  @include line-height(30px);
}
@mixin font-H5 {
  font-family: LINE-Seed-Sans, sans-serif;
  font-weight: 400;
  @include font-size(14px);
  @include line-height(24px);
  letter-spacing: -0.04em;
}
@mixin font-button {
  font-family: LINE-Seed-Sans, sans-serif;
  font-weight: 600;
  @include font-size(16px);
  @include line-height(20px)
}
@mixin font-H1T {
  font-family: LINE-Seed-Sans, sans-serif;
  font-weight: 700;
  @include font-size(51px);
  @include line-height(69px);
  letter-spacing: -0.02em;
}
@mixin font-H1M {
  font-family: LINE-Seed-Sans, sans-serif;
  font-weight: 700;
  @include font-size(32px);
  @include line-height(46px)
}
@mixin font-H2M {
  font-family: LINE-Seed-Sans, sans-serif;
  font-weight: 700;
  @include font-size(32px);
  @include line-height(42px)
}
