@import "../../styles/common";
@import "../../styles/fonts";
@import "../../styles/mixins";
@import '../../module/animation/animation';
.introduction-section-1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  overflow: hidden;
  height: 410px;
  position: relative;
  background: rgba(0,0,0,1);
  margin-top: 90px;
  @include fade-in-from-bottom;
  
  @include medium {
    height: 410px;
  }

  @include extra-large {
    margin-top: 193px;
    height: 620px;
  }

  video{
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 24px;
    z-index: 2;
    opacity: 0.5;
    background: rgba(0,0,0,0.5);
  }

  &-content-container{
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 3;
  }

  .video-title {
    @include font-H3;
  }

  .video-text {
    margin-top: 10px;
    max-width: 640px;
    text-align: center;
    @include font-H3-bold;
    @include medium {
      margin-top: 44px;
      @include font-H2;
    }
    @include extra-large {
      margin-top: 44px;
      @include font-H1;
    }
  }
}
