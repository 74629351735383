@import "../../styles/common";
@import "../../styles/fonts";
@import "../../styles/mixins";
@import "../../module/animation/animation";

.brand-section-3 {
  @include fade-in-from-bottom-paused;

  display: flex;
  justify-content: center;
  margin-top: 124px;

  @include medium {
    margin-top: 224px;
  }

  @include extra-large {
    margin-top: 305px;
  }

  &-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 38px;
    background-image: url(../../assets/image/brand/brand-more-background.png);
    background-repeat: no-repeat;
    background-position: top;
    width: 100%;
    height: 388px;
    border-radius: 26px;

    @include medium {
      gap: 22px;
      height: 282px;
    }

    @include extra-large {
      gap: 56px;
      height: 484px;
    }

    &-title {
      font-weight: 700;
      font-size: 32px;
      line-height: 42px;
      width: 205px;
      text-align: center;

      @include medium {
        width: 390px;
        font-size: 36px;
        line-height: 48px;
      }
    }

    &-button {
      padding: 12px 97px;
      font-weight: 700;
      font-size: 18px;
      line-height: 30px;
      color: #000;
      letter-spacing: -0.72px;
      background-color: #fff;
      border-radius: 8px;
      cursor: pointer;
    }
  }
}
