@import '../../styles/fonts';
@import "./../../styles/variable";
@import '../../module/animation/animation';

$small-image-width: 152px;
$small-image-margin: 10px;
$medium-image-width: 421px;
$medium-image-margin: 25px;
$extra-large-image-width: 537px;
$extra-large-image-margin: 32px;
@keyframes move-right-small {
    from { left: -1620px; }
    to { left: -810px; }
}

@keyframes move-right-medium {
    from { left: -4460px; }
    to { left: -2230px; }
}

@keyframes move-right-extra-large {
    from { left: -5690px; }
    to { left: -2845px; }
}

@keyframes move-left-small {
    from { left: -810px; }
    to { left: -1620px; }
}

@keyframes move-left-medium {
    from { left: -2230px; }
    to { left: -4460px; }
}

@keyframes move-left-extra-large {
    from { left: -2845px; }
    to { left: -5690px; }
}

.hidden-area{
    width: 100%;
    overflow: hidden;
}

.team-culture-section-1{
  @include fade-in-from-bottom;
    margin-top: 90px;
    @include medium{
        margin-top: 130px;
    }
    @include extra-large{
        margin-top: 377px;
    }
    .title{
        @include font-H2;
        @include extra-large{
            @include font-H1;
        }
    }
    .image-flow{
        position: relative;
        height: 194px;
        margin-top: 65px;
        @include medium{
            height: 538px;
            margin-top: 71px;
        }
        @include extra-large{
            height: 688px;
        }

        &-row{
            position: absolute;
            display: flex;
            animation-duration: 60s;
            animation-iteration-count: infinite;
            animation-direction: normal;
            animation-timing-function: linear;

            img{
                width: $small-image-width;
                height: 92px;
                border-radius: $small-image-margin;
                margin-right: 10px;
                object-fit: cover;
                &:last-child{
                    margin-right: 0;
                }

                @include medium{
                    width: $medium-image-width;
                    height: 256px;
                    margin-right: $medium-image-margin;
                }
                @include extra-large{
                    width: $extra-large-image-width;
                    height: 328px;
                    margin-right: $extra-large-image-margin;
                }
            }

            &.top{
                top: 0;
                animation-name: move-right-small;
                @include medium{
                    animation-name: move-right-medium;
                }
                @include extra-large{
                    animation-name: move-right-extra-large;
                }
            }

            &.bottom{
                bottom: 0;
                animation-name: move-left-small;
                @include medium{
                    animation-name: move-left-medium;
                }
                @include extra-large{
                    animation-name: move-left-extra-large;
                }
            }
        }
    }

    .desc-container{
        margin-top: 86px;
        position: relative;
        background-image: url("../../assets/image/team-culture-section-1/background-1-small.png");
        background-size: contain;
        background-repeat: no-repeat;
        width: 350px;
        height: 343px;
        padding: 40px;
        display: flex;
        align-items: flex-end;
        @include medium{
            background-image: url("../../assets/image/team-culture-section-1/background-1.png");
            width: 794px;
            height: 343px;
        }
        @include extra-large{
            width: 1200px;
            height: 520px;
        }
        .desc{
            z-index: 1;
            @include font-H4;
            @include medium{
                @include font-H3;
            }
        }
        &::before{
            content: "";
            opacity: 0.5;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #000;
        }
    }
}
