@import "../../styles/fonts";
@import "../../module/animation/animation";

.home-section-3 {
  background-image: url(../../assets/image/home-section-2/home-section-background-reverse.png);
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: $mobile-header-height 0 10px;

  @include disable-scrollbar;
  @include medium {
    padding: $header-height 0 10px;
  }

  &-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &::-webkit-scrollbar {
      display: none;
    }
    @include extra-large {
    }
  }

  &-title {
    @include font-H4-bold;
    line-height: 24px;

    @include medium {
      @include font-H2;
    }
    @include extra-large {
    }
  }

  &-swiper {
    width: 100%;
    margin-top: 20px;
    overflow: initial;

    .swiper-pagination {
      position: relative;
      margin-top: 10px;
      @include medium {
        margin-top: 30px;
      }
    }

    .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      border-radius: 4px;
      transform: none;
      transition: none;
      background: #d9d9d9;
      opacity: 1;

      &.swiper-pagination-bullet-active {
        width: 20px;
        background: #253BFF;
      }
    }
  }

  &-news-grid {
    width: 100%;

    @include medium {
      margin-top: 40px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(1, 410px);
      column-gap: 15px;
      row-gap: 20px;
    }
    @include extra-large {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &-news-item {
    padding: none;
    border: none;
  }

  &-link {
    margin: 0 auto 0;
    padding: 12px 20px;
    border: 1px solid #3d3d3d;
    border-radius: 8px;
    @include hover-button;

    @include medium {
      margin-top: 30px;
    }
    @include extra-large {
      display: flex;
      margin-top: 50px;
    }
  }
}
