@import "../styles/fonts";
@import "../styles/variable";

#community {
  background-image: url(../assets/image/background/community-background.jpg);
  background-repeat: no-repeat;
  overflow: hidden;
  background-position: top;
  background-size: contain;
}
