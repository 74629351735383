@import "../../styles/common";
@import "../../styles/fonts";
@import "../../styles/mixins";
@import "../../module/animation/animation";

.brand-section-2 {
  @include fade-in-from-bottom-paused;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 132px;

  @include medium {
    margin-top: 310px;
  }

  @include extra-large {
    margin-top: 276px;
  }

  &-title {
    width: fit-content;
    font-weight: 700;
    font-size: 32px;
    line-height: 42px;
    color: transparent;
    background: linear-gradient(to right, #5cf3ff, #4f6bf4);
    background-clip: text;
    -webkit-background-clip: text;

    @include medium {
      font-size: 74px;
      line-height: 100px;
      letter-spacing: -1.48px;
    }
  }

  &-concept-wrapper {
    margin-top: 67px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 177px;

    @include medium {
      gap: 166px;
      margin-top: 153px;
    }

    .brand-section-2-concept {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 90px;

      @include medium {
        flex-direction: row;
        gap: 0;
      }

      &:nth-child(2n) {
        @include medium {
          flex-direction: row-reverse;
        }
      }

      &-image {
        object-fit: contain;

        &.symbol {
          width: 276px;

          @include extra-large {
            width: 345px;
          }
        }

        &.text-logo {
          width: 322px;

          @include extra-large {
            width: 448px;
          }
        }
      }

      &-introduction {
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 24px;

        @include medium {
          gap: 55px;
          padding: 45px 54px;
          align-items: normal;
        }

        @include extra-large {
          gap: 65px;
        }

        // bracket
        &::before {
          @include medium {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 30px;
            height: 30px;
            border-top: 5px solid #d9d9d9;
            border-left: 5px solid #d9d9d9;
          }
        }

        &-title {
          font-weight: 700;
          font-size: 26px;
          line-height: 30px;

          @include extra-large {
            font-size: 36px;
            line-height: 48px;
          }
        }

        &-description {
          width: 248px;
          font-weight: 400;
          font-size: 18px;
          line-height: 30px;
          letter-spacing: -0.72px;
          color: #838990;
          text-align: center;

          @include medium {
            text-align: start;
          }

          @include extra-large {
            width: 568px;
            font-weight: 700;
            font-size: 26px;
            line-height: 42px;
            letter-spacing: 0;
          }
        }
      }
    }
  }
}
