@import '../../styles/fonts';
@import '../../module/animation/animation';

.tech-section-2{
    position: relative;
    height: 607.78px;
    margin-top: 117px;
  @include fade-in-from-bottom-paused;
    @include medium{
        height: 331.78px;
        margin-top: 329px;
    }
    @include extra-large{
        height: 510px;
        margin-top: 300px;
    }

    @include medium{
        display: flex;
    }

    &-circle-container{
        position: absolute;
        min-width: 346.78px;
        height: 331.78px;
        margin-top: 61px;
        @include medium{
            margin: 0;
        }
        @include extra-large{
            min-width: 534px;
            height: 510px;
            @include font-H2;
        }
    }


    &-circle1{
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
        
        background-image: url('../../assets/image/tech-section-2/circle1.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;

        text-align: center;

        width: 214.44px;
        height: 214.44px;
        @include font-H4-bold;

        @include extra-large{
            width: 330px;
            height: 330px;
            @include font-H2;
        }
    }

    &-circle2{
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        
        background-image: url('../../assets/image/tech-section-2/circle2.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;

        text-align: center;

        top: 92px;
        left: 107px;
        width: 239.78px;
        height: 239.78px;
        @include font-H4-bold;

        @include extra-large{
            top: 141px;
            left: 165px;
            width: 369px;
            height: 369px;
            padding: 0 0 0 0;
            @include font-H2;
        }
    }

    &-r{
        display: none;
        @include medium{
            display: initial;
            width: 30px;
            height: 30px;
            margin-right: 24px;
            margin-left: 369px;   
            object-fit: contain;
        }
        @include extra-large{
            
            margin-left: 578px;
        }
        
    }

    &-mca{
        @include font-H3-bold;
        @include medium{
            margin-top: 45px;
            @include font-H2;
        }
        @include extra-large{
            @include font-H2;
        }

        &-explain{
            margin-top: 35px;
            @include font-H4;
            color: #838990;
      
            @include medium{
                  margin-top: 30px;
                  @include font-H4-bold;
            }
            @include extra-large{
                margin-top: 65px;
                @include font-H3;
            }
        }
    }
}
