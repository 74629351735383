@import "../styles/variable";
@import "../styles/mixins";

#Brand {
  background-image: url(../assets/image/background/brand-background.jpg);
  background-repeat: no-repeat;
  background-size: 414px auto;
  background-position: top -44px left 50%;

  @include medium {
    background-size: 1569px auto;
    background-position: top -442px left 50%;
  }
}
