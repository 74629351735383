@import "../styles/fonts";
@import "../styles/variable";

.header {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: $mobile-header-height;
  top: 0;
  align-items: center;
  z-index: 100;
  -webkit-transition: background-color 0.4s;
  -moz-transition: background-color 0.4s;
  -ms-transition: background-color 0.4s;
  -o-transition: background-color 0.4s;
  transition: background-color 0.4s;

  @include medium {
    height: $header-height;
  }

  &-container {
    display: flex;
    height: $mobile-header-height;
    justify-content: space-between;
    align-items: center;
    @include medium {
      height: $header-height;
    }
  }

  &-menu {
    display: none;
    align-items: center;
    cursor: pointer;

    @include medium {
      display: flex;
    }
    @include extra-large {
      display: flex;
    }
  }

  .header-mobile-menu-button-wrapper {
    @include medium {
      display: none;
    }
    @include extra-large {
      display: none;
    }

    button {
      cursor: pointer;
    }

    .header-mobile-menu-icon {
      width: 24px;
    }
  }

  .logo {
    width: 107px;
    object-fit: contain;
    margin-right: 68px;
  }

  .menu {
    @include hover-button;
    @include font-H3;
    color: white;
    @include medium {
      @include font-H4;
    }
    margin-right: 14px;
    &:last-child {
      margin-right: 0;
    }
  }

  &-sub-menu {
    position: absolute;
    display: flex;
    align-items: center;
    top: $mobile-header-height;
    height: 0;
    transition: height 0.4s;
    @include medium {
      top: $header-height;
    }

    &-background {
      position: absolute;
      left: -100vw;
      width: 200vw;
      height: 100%;
      background-color: #171b21;
      z-index: -1;
    }
  }

  .sub-menu {
    @include font-H3;
    color: white;
    @include hover-button;
    @include medium {
      @include font-H4;
    }
    margin-right: 14px;
    &:last-child {
      margin-right: 0;
    }
  }

  &-mobile-menu {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 4px; /* 스크롤바의 너비 */
    }
    &::-webkit-scrollbar-thumb {
      background: #3f4553; /* 스크롤바의 색상 */
    }
    &-column {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      margin-bottom: 20px;
      &-tab {
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 7.5px 0;
        transition: background-color 0.4s;
        &:hover {
          background-color: rgba($color: #8c91ad, $alpha: 0.3);
        }
        &-item {
          width: 370px;
          @include font-H4;
          text-decoration: none;
          color: #d9d9d9;
        }
      }
    }
    .main {
      width: 370px;
      padding: 5px 0;
      margin: 0 auto;
      margin-bottom: 10px;
      border-bottom: solid 1px #3f4553;
      color: white;
      @include font-H3;
    }
  }

  &-space {
    height: $header-height;
  }

  .select-lang {
    position: relative;
    visibility: hidden;
    align-items: center;
    @include medium {
      visibility: visible;
    }
    @include extra-large {
      visibility: visible;
    }

    &-button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 130px;
      padding: 7px 10px 7px 14px;
      border-radius: 50px;
      border: 1px solid #bebebe;
      color: white;
      @include font-H5;
      &-icon {
        width: 20px;
        height: 20px;
        object-fit: contain;
      }
    }
    &-container {
      transition: 300ms;
      visibility: hidden;
      opacity: 0;
      position: absolute;
      top: 54px;
      left: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      &-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 130px;
        height: 46px;
        padding: 20px;
        background: #171b21;
        border: 1px solid #31363c;
        border-radius: 12px 12px 0px 0px;
        color: #fff;
        cursor: pointer;
        transition: background-color 0.4s;
        &:hover {
          background-color: rgba($color: #ebf0f0, $alpha: 0.3);
        }
        &:last-child {
          border-radius: 0px 0px 12px 12px;
        }
      }
      &.visible {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}
