@import "../../styles/common";
@import "../../styles/fonts";
@import "../../styles/mixins";
@import "../../module/animation/animation";

.brand-section-1 {
  @include fade-in-from-bottom;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 126px;

  @include medium {
    margin-top: 512px;
  }

  @include extra-large {
    margin-top: 422px;
  }

  &-eqbr {
    &-ci {
      padding: 9px 30px;
      width: fit-content;
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;
      letter-spacing: -0.72px;
      border: 1px solid #fff;
      border-radius: 73.5px;

      @include medium {
        padding: 15px 42px;
        font-weight: 700;
        font-size: 26px;
        line-height: 42px;
        letter-spacing: 0;
      }
    }

    &-logo {
      margin-top: 60px;

      @include medium {
        margin-top: 124px;
      }

      &-img {
        width: 316px;
        object-fit: contain;

        @include medium {
          width: 488px;
        }

        @include extra-large {
        }
      }
    }

    &-ci-file {
      margin-top: 164px;
      width: 100%;

      @include medium {
        margin-top: 234px;
      }

      @include extra-large {
        margin-top: 270px;
      }

      &-title {
        font-weight: 700;
        font-size: 32px;
        line-height: 42px;
        text-align: center;

        @include medium {
          font-size: 36px;
          line-height: 48px;
          text-align: start;
        }

        @include extra-large {
          gap: 36px;
        }
      }

      &-download {
        display: flex;
        flex-direction: column;
        gap: 28px;
        margin-top: 28px;

        @include medium {
          flex-direction: row;
          gap: 22px;
          margin-top: 45px;
        }

        @include extra-large {
          gap: 36px;
        }

        &-button {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 10px;
          height: 148px;
          border-radius: 8px;
          background-color: #3628da;
          transition: background-color 0.4s;
          cursor: pointer;

          &:hover {
            background-color: #2c1fc7;
          }

          @include medium {
            flex: 1;
          }

          @include extra-large {
            height: 180px;
          }

          &-title {
            font-weight: 700;
            font-size: 26px;
            line-height: 42px;
          }

          &-size {
            font-size: 18px;
            line-height: 30px;
            letter-spacing: -0.72px;
          }
        }
      }
    }
  }
}
