@import "../../styles/mixins";
@import "../../module/animation/animation";

.brand-section-4 {
  @include fade-in-from-bottom-paused;
  margin-top: 100px;

  @include medium {
    margin-top: 220px;
  }

  @include extra-large {
    margin-top: 300px;
  }
}
