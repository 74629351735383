@import '../../styles/fonts';

.employment-recruit-detail-content{
    margin-top: 90px;
    @include medium{
        margin-top: 130px;
    }
    @include extra-large{
        margin-top: 214px;
    }
    &-tap{
        margin-bottom: 36px;
        @include font-H4;
        &-arrow{
            width: 8px;
            margin: 0 12px;
            object-fit: contain;
        }
    }
    &-title{
        margin-bottom: 73px;
        @include font-H2;
    }
    &-item{
        width: 100%;
        margin-bottom:70px;
        @include extra-large{
            width: 842px;
        }
        &-title{
            margin-bottom: 20px;
            @include font-H3;
        }
        &-description{
           @include font-H4;
        }
    }
    &-btn{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 150px auto 0 auto;
        width: 100px;
        height: 36px;
        background: rgba(235, 240, 240, 0.1);
        border-radius: 8px;
        @include font-H5;
        color: #fff;
        cursor: pointer;
        transition: background-color 0.4s;
        &:hover{
          background-color: rgba($color: #EBF0F0, $alpha: 0.3);
        }
        @include extra-large{
        margin: 170px auto 0 auto;

        }
    }
    &-right{
        display: block;
        position: -webkit-sticky;
        position: sticky;
        top: $header-height;
        &-box{
            width: 100%;
            //height: 306px;
            padding: 30px 20px;
            margin-top: 70px;
            background: #171B21;
            border: 1px solid #31363C;
            border-radius: 15px;
            @include font-H4;
            @include medium{
                padding: 30px 50px;
            }
            @include extra-large{
                width: 338px;
                //height: 306px;
                padding: 30px;
                margin-top: 0px;
                background: #171B21;
                border: 1px solid #31363C;
                border-radius: 15px;
            }
            &-line{
                width: 100%;
                height: 1px;
                margin: 20px 0;
                background-color: #31363C;
            }
            &-name{
                display: flex;
                &-key{
                    width: 100px;
                }
                &-value{
                    color: #158EFF;
                }
            }
            &-work{
                display: flex;
                &-key{
                    width: 100px;
                }
                &-value{
                    display: flex;
                    flex: 1;
                    color: #158EFF;
                }
            }
            &-work-place{
                display: flex;
                flex-direction: column;
                &-info{
                    display: flex;
                    margin-bottom: 15px;
                    &-key{
                        width: 100px;
                    }
                    &-value{
                        display: flex;
                        flex: 1;
                        color: #158EFF;
                        &:hover{
                            text-decoration: underline;
                            cursor: pointer;
                        }
                    }
                }
                &-image{
                    width: 100%;
                    object-fit: cover;
                    margin-bottom: 15px;
                    cursor: pointer;
                }

            }
            &-btn{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 36px;
                background: #253BFF;
                border-radius: 8px;
                @include font-H5;
                color: #fff;
                cursor: pointer;
                transition: background-color 0.4s;
                &:hover{
                    background-color: #162DF3;
                }
            }
    }
}
}
