@import "../../styles/fonts";
@import "../../module/animation/animation";

.home-section-1 {
  position: relative;
  background-image: url(../../assets/image/home-section-1/home-section-1-background.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  &-back-text {
    position: absolute;
    font-family: LINE-Seed-Sans;
    font-weight: 700;
    font-size: 120px;

    text-align: center;
    letter-spacing: -0.02em;

    background: linear-gradient(
      270deg,
      #ffffff 0%,
      rgba(255, 255, 255, 0.3) 11.98%,
      rgba(255, 255, 255, 0) 46.67%,
      rgba(255, 255, 255, 0.3) 88.02%,
      #ffffff 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    white-space: nowrap;
    z-index: 1;

    @include medium {
      font-size: 233px;
    }
    @include extra-large {
      font-size: 339px;
    }
  }

  &-globe {
    position: absolute;
    z-index: 2;
  }

  &-front-text {
    position: absolute;
    z-index: 3;
    @include font-H2M;
    text-align: center;
    @include medium {
      @include font-H1T;
    }
    @include extra-large {
      @include font-H1;
    }
  }

  &-link {
    position: absolute;
    z-index: 4;
    margin-top: 220px;
    @include hover-button;
    @include medium {
      margin-top: 340px;
    }
    @include extra-large {
      margin-top: 500px;
    }
  }
}
