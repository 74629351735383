@import '../../styles/fonts';
@import '../../module/animation/animation';
.tech-section-1{
  margin-top: 90px;
  @include fade-in-from-bottom;
  @include medium{
      margin-top: 130px;
  }
  @include extra-large{
    margin-top: 218px;
  }
  .title{
    @include font-H2-mobile;
    @include medium{
      @include font-H1;
    }
  }

  &-r{
    display: none;
    width: 30px;
    height: 30px;
    margin-top: 70px;
    object-fit: contain;
    @include medium{
      display: initial;
    }
  }

  &-container{
    margin-top: 50px;
    padding: 0 17px;
    @include medium{
      display: flex;
      padding: none;
      justify-content: space-between;
      margin-top: 12px;
      padding-left: 54px;
    }
  }

  &-equalize{
    @include font-H3-bold;
    @include medium{
      @include font-H2;
    }

    &-explain{
      margin-top: 35px;
      @include font-H4;
      color: #838990;

      @include medium{
        margin-top: 30px;
      @include font-H4-bold;
      }
      @include extra-large{
        margin-top: 37px;
        @include font-H3;
      }
    }

    &-sub{
      max-width: 211px;
      margin: 86px auto 0;
      text-align: center;

      @include font-H4-bold;
      @include medium{
        max-width: none;
        min-width: 211px;
        margin: none;
        margin-left: 113px;
        text-align: right;
      }
      @include extra-large{
        min-width: 293px;
        margin-left: 295px;
        text-align: right;
        @include font-H3;
      }
    }
  }

  &-grid{
    display: grid;
    margin-top: 61px;
    grid-template-columns: repeat(1, 1fr);
    row-gap: 70px; 
    @include medium{
      margin-top: 158px;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 43px; 
    }
    @include extra-large{
      margin-top: 116px;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 174px; 
    }
  }

  &-value{
    @include font-H1;
    white-space: nowrap;
    line-height: 74px;
  }

  &-unit{
    @include font-H2;
  }

  &-link{
    width: fit-content;
    margin: 60px auto 0;
    padding: 12px 20px;
    border: 1px solid #3D3D3D;
    border-radius: 8px;
    transition: background-color 0.4s;
    &:hover{
      background-color: rgba($color: #F1F4FA, $alpha: 0.2);
    }
    @include extra-large{
      margin: 100px auto 0;
    }
  }
}
 