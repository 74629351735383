@import "../../styles/fonts";
@import "../../module/animation/animation";

.community-section-3 {
  margin-top: 107px;
  position: relative;
  @include fade-in-from-bottom-paused;
  @include medium {
    margin: 283px auto 0;
  }

  &-title {
    margin-bottom: 23px;
    @include font-H3-bold;
    text-align: center;
    @include medium {
      margin-bottom: 30px;
      @include font-H2;
    }
    @include extra-large {
      margin-bottom: 36px;
      @include font-H1;
    }
  }

  &-sub-title {
    margin-top: 54px;
    @include font-H4;
    text-align: center;
    @include extra-large {
      width: 780px;
      margin: 0 auto;
      @include font-H3;
    }
  }

  &-partners {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    place-items: center;
    width: 100%;
    margin-top: 33px;
    @include medium {
      grid-template-columns: repeat(5, 1fr);
      margin-top: 33px;
    }

    @include extra-large {
      grid-template-columns: repeat(4, 1fr);
      margin-top: 20px;
    }
    &-item {
      display: flex;
      width: fit-content;
      height: 124px;
      @include medium {
        height: 120px;
      }
      @include extra-large {
        height: 150px;
      }
      img {
        object-fit: contain;
        max-width: 150px;
        min-width: 150px;
        @include medium {
          max-width: 120px;
          min-width: 120px;
        }
        @include extra-large {
          max-width: 180px;
          min-width: 180px;
        }
      }
    }
  }
}
