@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../module/animation/animation';

.news-list {
  margin-top: 90px;
  @include medium{
    margin-top: 60px;
  }
  &-category-selector{
    display: flex;
    margin: 0 auto;
    overflow: auto;

    @include medium{
      width: fit-content;
    }

    &-button{
      margin-right: 11px;
      padding: 6px 25px;
      border-radius: 8px;
      @include font-H5;
      color: white;
      white-space: nowrap;
      transition: background-color 0.4s;
      &:hover{
        background-color: rgba($color: #EBF0F0, $alpha: 0.1);
      }
      &:last-child{
        margin-right: 0;
      }
      &.active{
        background-color: rgba($color: #EBF0F0, $alpha: 0.1);
      }
      &:hover{
        background-color: rgba($color: #EBF0F0, $alpha: 0.1);
      }
    }
  }

  &-container{
    margin-top: 77px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    row-gap: 24px;
    column-gap: 15px;

    @include medium{
      grid-template-columns: repeat(2, 1fr);
    }
    @include extra-large{
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &-pagination{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 110px;

    &-prev{
      margin-right: 20px;
      @include medium{
        margin-right: 70px;
      }
    }

    &-next{
      @include medium{
        margin-left: 50px;
      }
    }

    &-number{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      border-radius: 16px;
      margin-right: 20px;
      color: #7E87A9;
      transition: background-color 0.2s;
      &:last-child{
        margin-right: 0;
      }

      &.active{
        color: white;
      }
    }

    img{
      width: 26px;
      height: 26px;
      object-fit: contain;
    }
  }
}
