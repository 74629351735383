@import "..//styles/common";
@import "../styles/fonts";
@import "../styles/mixins";
@keyframes arrow-move {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(12px);
  }
}

.recruit {
  width: 100%;
  height: 594px;
  background-image: url(../assets/image/team-culture-section-4/background.png);
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include medium {
    height: 751px;
  }

  @include extra-large {
    height: 753px;
  }

  &-title {
    @include font-H2-mobile;
    width: 279px;
    text-align: center;

    @include medium {
      @include font-H1;
      width: 631px;
    }
  }

  &-link {
    @include font-H4-bold;
    margin-top: 44px;
    border: 1px solid #ffffff;
    border-radius: 8px;
    padding: 12px 20px;
    color: #fff;
    display: flex;
    align-items: center;
    transition: background-color 0.4s;

    &:hover {
      background-color: rgba($color: #ebf0f0, $alpha: 0.1);
    }

    &:hover .recruit-link-arrow {
      animation: arrow-move 1.5s linear infinite;
    }

    .recruit-link-arrow {
      width: 28px;
      height: 28px;
      margin-left: 15px;
    }
  }
}
