@import '_variable';
@import 'mixins';

.container {
  @include small(){
    @include container-small;
  }
  @include medium(){
    @include container-medium;
  }
  // @include large(){
  //  @include container-large;
  // }
  @include extra-large(){
    @include container-extra-large;
  }
}
