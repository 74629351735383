@import "../../styles/fonts";
@import "../../module/animation/animation";

.home-section-2 {
  background-image: url(../../assets/image/home-section-2/home-section-background.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: $mobile-header-height 0 10px;

  @include disable-scrollbar;
  @include medium {
    padding: $header-height 0 10px;
  }

  &-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &-title {
    width: 350px;
    @include font-H4-bold;
    line-height: 34px;
    @include medium {
      width: 694px;
      @include font-H2;
    }
    @include extra-large {
      width: 700px;
    }
  }

  &-flex {
    display: flex;
    margin-top: 70px;
  }

  &-swiper {
    margin-top: 10px;
    overflow: initial;
    @include medium {
      margin-top: 80px;
      .swiper-slide {
        width: 530px;
      }
    }

    .swiper-pagination {
      position: relative;
      margin-top: 20px;
    }

    .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      border-radius: 4px;
      transform: none;
      transition: none;
      background: #d9d9d9;
      opacity: 1;

      &.swiper-pagination-bullet-active {
        width: 20px;
        background: #253BFF;
      }
    }
  }

  &-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-right: 110px;
    @include medium {
      width: 530px;
    }

    &:last-child {
      margin-right: 0;
    }

    &-text {
      width: 100%;
      margin-top: 10px;
      @include font-H5;
      // @include font-H4-bold;
      color: #838990;
      white-space: break-spaces;
      word-break: keep-all;

      @include medium {
        margin-top: 33px;
        @include font-H3;
      }
    }

    &-link {
      margin: 10px auto 0;
      padding: 12px 20px;
      border: 1px solid #3d3d3d;
      @include hover-button;
      @include medium {
        margin: 33px auto 0 0;
      }
    }

    &-img {
      width: 100%;
      object-fit: contain;
    }
  }

  &-hilight-text {
    color: white;
  }
}
