@import "../styles/variable";
@import "../styles/fonts";
@import "../module/animation/animation.scss";

.page-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 120px;
  @include fade-in-from-bottom;
  @include medium {
    padding-top: 220px;
  }
  @include extra-large {
    padding-top: 260px;
  }
  .title {
    @include font-H2;
    @include medium {
      @include font-H1;
    }
  }
  .sub-title {
    @include font-H4-bold;
    text-align: center;
    margin-top: 15px;
    @include medium {
      margin-top: 25px;
      @include font-H2;
    }
    @include extra-large {
      margin-top: 35px;
    }
  }
}
