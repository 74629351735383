@import "../../styles/fonts";
@import "./../../styles/variable";
@import "../../module/animation/animation";

.community-section-1 {
  position: relative;
  width: 100%;
  height: 250px;
  margin-top: 150px;
  @include fade-in-from-bottom;
  @include medium {
    height: 500px;
    margin-top: 450px;
  }
  @include extra-large {
    height: 962px;
    margin-top: 300px;
  }
  background: linear-gradient(
    180deg,
    rgba(0, 11, 72, 0) 0%,
    rgba(10, 18, 64, 0.203037) 4.85%,
    rgba(28, 12, 74, 0.521822) 19.15%,
    #170058 40.59%,
    #0e0526 67.54%,
    #000000 90.41%,
    rgba(0, 0, 0, 0) 100%
  );
  &-block {
    position: absolute !important;
    width: 100%;
    height: 100%;
    background: none !important;
    &-content {
      width: 100%;
      height: 100%;
      max-width: 370px;
      margin: 0 auto;
      @include medium {
        max-width: 754px;
      }
      @include extra-large {
        max-width: 1200px;
      }
      &-img {
        position: absolute;
        width: 100%;
        max-width: 370px;
        object-fit: contain;
        @include medium {
          top: 27px;
          max-width: 754px;
        }
        @include extra-large {
          top: 164px;
          max-width: 1200px;
        }
      }
    }
  }
  &-company {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding-top: 144px;
    &-top {
      &-box {
        position: relative;
        width: 448px;
        height: 260px;
        margin-left: 279px;
        background: rgba(20, 20, 20, 0.5) !important;
        border-radius: 19px !important;
        z-index: 3;
        border: 1px solid #3c4040;
        &-block {
          border-radius: 19px;
          padding: 38px 30px;
          &-name {
            margin-bottom: 18px;
            @include font-H3-bold;
          }
          &-region {
            margin-bottom: 18px;
            @include font-H4;
            &-detail {
              display: grid;
              @include font-H5;
              opacity: 0.6;
            }
          }
          &-description {
            @include font-H4;
          }
        }
      }
    }
    &-middle {
      display: flex;
      margin-top: 30px;
      padding-left: 474px;
      margin-bottom: 34px;
      &-button {
        position: relative;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        &-left {
          position: relative;
          width: 60px;
          height: 60px;
          margin-top: 35px;
          margin-right: 330px;
        }
      }
    }
    &-bottom {
      display: flex;
      &-left {
        &-button {
          position: relative;
          width: 60px;
          height: 60px;
          margin-left: 402px;
          margin-top: 28px;
          margin-bottom: 23px;
        }
        &-box {
          position: relative;
          width: 448px;
          height: 328px;
          margin-left: 77px;
          background: rgba(20, 20, 20, 0.5) !important;
          border: 1px solid #3c4040;
          border-radius: 19px;
          z-index: 3;
          &-block {
            padding: 38px 30px;
            border-radius: 19px;
            &-name {
              margin-bottom: 18px;
              @include font-H3-bold;
            }
            &-region {
              margin-bottom: 18px;
              @include font-H4;
              &-detail {
                display: grid;
                @include font-H5;
                opacity: 0.6;
              }
            }
            &-description {
              margin-bottom: 18px;
              @include font-H4;
            }
            &-button {
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 10px 16px;
              width: fit-content;
              border: 1px solid #3d3d3d;
              border-radius: 8px;
              @include font-H4-bold;
              transition: background-color 0.4s;
              &:hover {
                background-color: rgba($color: #f1f4fa, $alpha: 0.2);
              }
              img {
                width: 28px;
                height: 28px;
                // margin-left: 16px;
                object-fit: contain;
              }
            }
          }
        }
      }
      &-right {
        &-box {
          position: relative;
          width: 448px;
          height: 330px;
          margin-left: 192px;
          border: 1px solid #3c4040;
          background: rgba(20, 20, 20, 0.5) !important;
          border-radius: 19px;
          z-index: 3;
          &-block {
            border-radius: 19px;
            padding: 38px 30px;
            &-name {
              margin-bottom: 18px;
              @include font-H3-bold;
            }
            &-region {
              margin-bottom: 18px;
              @include font-H4;
              &-detail {
                display: inherit;
                @include font-H5;
                opacity: 0.6;
              }
            }
            &-description {
              margin-bottom: 18px;
              @include font-H4;
            }
            &-button {
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 10px 16px;
              width: fit-content;
              border: 1px solid #3d3d3d;
              border-radius: 8px;
              @include font-H4-bold;
              transition: background-color 0.4s;
              &:hover {
                background-color: rgba($color: #f1f4fa, $alpha: 0.2);
              }
              img {
                width: 28px;
                height: 28px;
                // margin-left: 16px;
                object-fit: contain;
              }
            }
          }
        }
      }
    }
  }
  &-button {
    &-top {
      display: flex;
      &-button1 {
        position: relative;
        width: 40px;
        height: 40px;
        margin: 90px 225px 0 30px;
        z-index: 3;
        @include medium {
          width: 38px;
          height: 38px;
          margin-left: 100px;
          margin-right: 515px;
          margin-top: 210px;
        }
      }
      &-button2 {
        position: relative;
        width: 40px;
        height: 40px;
        margin-top: 95px;
        z-index: 3;
        @include medium {
          width: 38px;
          height: 38px;
          margin-top: 225px;
        }
      }
    }
    &-bottom {
      &-button3 {
        position: relative;
        width: 40px;
        height: 50px;
        margin-left: 270px;
        cursor: pointer;
        z-index: 3;
        @include medium {
          width: 38px;
          height: 38px;
          margin-top: 50px;
          margin-left: 600px;
        }
      }
    }
  }
  &-bottom {
    &-content {
      width: 370px;
      margin: 120px auto 0;
      padding: 25px;
      background: rgba(20, 20, 20, 0.5);
      border: 1px solid #3c4040;
      border-radius: 19px;
      @include medium {
        width: 754px;
        margin: 250px auto 0;
        padding: 45px 63px 45px 40px;
        background: rgba(20, 20, 20, 0.5);
        border: 1px solid #3c4040;
        border-radius: 19px;
      }
      &-name {
        margin-bottom: 14px;
        @include font-H3-bold;
      }
      &-region {
        margin-bottom: 14px;
        @include font-H4;
        color: #8f8f8f;
      }
      &-description {
        margin-bottom: 28px;
        @include font-H4-bold;
        @include medium {
          @include font-H3-bold;
        }
      }
      &-link {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        padding: 10px 16px;
        width: fit-content;
        border: 1px solid #3d3d3d;
        border-radius: 8px;
        @include font-H4-bold;
        transition: background-color 0.4s;
        &:hover {
          background-color: rgba($color: #f1f4fa, $alpha: 0.2);
        }
        img {
          width: 28px;
          height: 28px;
          // margin-left: 16px;
          object-fit: contain;
        }
      }
    }
  }
}

.button {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: #3e74ff;
  cursor: pointer;
}

.waves {
  position: absolute;
  width: 40px;
  height: 40px;
  top: -8px;
  left: -8px;
  background: #3e74ff;
  opacity: 0;
  border-radius: 100%;
  z-index: 0;
  -webkit-animation: waves 3s ease-in-out infinite;
  animation: waves 3s ease-in-out infinite;
  z-index: -1;
  @include extra-large {
    width: 60px;
    height: 60px;
    top: 0;
    left: 0;
    z-index: 20;
  }
}
.waves.wave-1 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.waves.wave-2 {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.waves.wave-3 {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

@keyframes waves {
  0% {
    -webkit-transform: scale(0.2, 0.2);
    transform: scale(0.2, 0.2);
    opacity: 0;
    @include extra-large {
      -webkit-transform: scale(0.4, 0.4);
      transform: scale(0.4, 0.4);
    }
  }
  50% {
    opacity: 0.9;
  }
  100% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 0;
  }
}
.en {
  height: 280px !important;
}
