@import "../../styles/fonts.scss";
@import "../../module/animation/animation";

.community-section-4 {
  width: 350px;
  padding-top: 228px;
  @include fade-in-from-bottom-paused;
  @include medium {
    width: 794px;
    padding-top: 226px;
  }
  @include extra-large {
    width: 740px;
    padding-top: 257px;
  }

  &-contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include font-H4;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 350px;
    height: 464px;
    background-image: url(../../assets/image/community-section-4/contact-background-small.png);
    padding: 20px;
    @include medium {
      width: 794px;
      height: 320px;
      background-image: url(../../assets/image/community-section-4/contact-background-medium.png);
    }
    @include extra-large {
      width: 1200px;
      height: 484px;
      background-image: url(../../assets/image/community-section-4/contact-background-extraLarge.png);
    }

    &-subtitle {
      text-align: center;
      color: #fff;
      @include font-H5;
      // @include medium {
      //   @include font-H5;
      // }
      @include extra-large {
        @include font-H4;
      }
    }

    &-title {
      margin-top: 22px;
      text-align: center;
      color: #fff;
      @include font-H3;
      @include medium {
        margin-top: 32px;
      }
      @include extra-large {
        margin-top: 56px;
        @include font-H2;
      }
    }

    &-button {
      width: 278px;
      height: 54px;
      margin-top: 60px;
      background: #fff;
      border-radius: 8px;
      color: black;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      cursor: pointer;
      @include medium {
        margin-top: 38px;
      }
      @include extra-large {
        margin-top: 56px;
      }
    }
  }
}
