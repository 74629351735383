@import "../styles/fonts";
@import "../styles/variable";
@import "../styles/mixins";

#newsroom {
  overflow: hidden;
  background-image: url(../assets/image/newsroom/newsroom-background.png);
  background-repeat: no-repeat;
  background-position: top;
  @include extra-large{
    background-size: contain;
  }
}
