@import "../styles/mixins";

#introduction {
  background-image: url(../assets/image/background/introduction-background.jpg);
  background-repeat: no-repeat;
  background-position: top;
  @include extra-large {
    background-size: contain;
  }
}
