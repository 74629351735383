@import '../../styles/fonts';
@import '../../module/animation/animation';

.tech-section-3{
    // display: none;
    margin-top: 117px;
    @include fade-in-from-bottom-paused;
    @include medium{
        display: block;
        margin-top: 314px;
    }
    @include extra-large{
        margin-top: 374px;
    }
    &-r{
        display: none;
        @include medium{
            display: block;
        }
    }
    &-container{
        @include medium{
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0 30px;
        }
        @include extra-large{
            display: flex;    
            flex-direction: row;
            align-items: flex-start;
            margin-top: 15px;
            padding: 0;
            padding-left: 54px;
        }        
    }

    &-evm{
        @include font-H3-bold;
        @include medium{
            @include font-H2;
            margin-top: 45px;
            text-align: start;
        }
        @include extra-large{
            margin-top: 0;
            @include font-H2;
            text-align: left;
        }

        &-explain{
            margin-top: 35px;
            @include font-H4;
            color: #838990;
            @include medium{
                margin-top: 30px;
            @include font-H4-bold;
            }
            @include extra-large{
                margin-top: 57px;
                @include font-H3;
                
            }
        }
    }

  &-image{
    margin-top:80px;
    width: 350px;
    object-fit: contain;
    @include medium{
        width: 522px;
        height: 482px;
        margin: 107px auto 0;
    }

    @include extra-large{
        margin: 0;
        margin-left: 72px;
        object-fit: contain;
    }
  }
}
