@import "../../styles/fonts";
@import "../../styles/variable";
@import "../../module/animation/animation";

@keyframes arrow-move {
  0% {
    transform: translateX(6px);
  }

  100% {
    transform: translateX(15px);
  }
}

.home-section-4 {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;

  @include extra-large {
    display: flex;
    flex-direction: row;
  }

  &-swiper {
    position: relative;
    height: 100%;
    width: 100%;

    .swiper-pagination {
      width: fit-content;
      bottom: 40px;
      left: 50%;
      transform: translateX(-50%);
    }

    .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      border-radius: 4px;
      transform: none;
      transition: none;
      background: #d9d9d9;
      opacity: 1;

      &.swiper-pagination-bullet-active {
        width: 20px;
        background: #253bff;
      }
    }
  }

  &-height50 {
    position: relative;
    width: 100%;
    max-height: 422px;
    height: 70%;

    @include medium {
      max-height: none;
      height: fit-content;
    }

    @include extra-large {
      height: 40vh;
    }

    &.front {
      padding: 0 calc((100vw - $small-container) / 2);

      @include medium {
        padding: 0 calc((100vw - $medium-container) / 2);
      }

      @include extra-large {
        padding-left: calc((100vw - $extra-large-container) / 2);
        padding-right: 87px;
      }
    }

    &.back {
      padding: 0 calc((100vw - $small-container) / 2);

      @include medium {
        padding: 0 calc((100vw - $medium-container) / 2);
      }

      @include extra-large {
        padding-right: calc((100vw - $extra-large-container) / 2);
        padding-left: 87px;
      }
    }
  }

  &-front {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #0e1320;
  }

  &-back {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #2c28da;
  }

  &-title {
    @include font-H3;
  }

  &-sub-title {
    margin-top: 26px;
    @include font-H3;
    line-height: 34px;
  }

  &-partners {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    place-items: center;
    width: 100%;
    margin-top: 16px;
    grid-column-gap: 55px;

    @include medium {
      grid-template-columns: repeat(5, 1fr);
      grid-column-gap: 73px;
      margin-top: 33px;
    }

    @include extra-large {
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 38px;
      margin-top: 20px;
    }

    &-item {
      display: flex;
      width: fit-content;
      height: 50px;
      @include medium {
        height: 70px;
      }
      @include extra-large {
        height: 70px;
      }
      img {
        object-fit: contain;
        max-width: 80px;
        min-width: 80px;

        @include medium {
          max-width: 100px;
          min-width: 100px;
        }
      }
    }
  }

  &-contact-us {
    display: flex;
    align-items: center;
    width: fit-content;
    gap: 8px;
    padding: 12px 20px;
    margin-top: 148px;
    border: solid 1px #dddddd;
    border-radius: 8px;
    @include font-H4-bold;
    color: white;
    transition: background-color 0.4s;

    &:hover {
      background-color: rgba($color: #f1f4fa, $alpha: 0.2);
    }

    @include medium {
      margin-top: 137px;
    }

    @include extra-large {
      margin-top: 194px;
    }

    &-icon {
      width: 20px;
      height: 20px;
      // transform: translateX(10px);
    }

    &:hover &-icon {
      // animation: arrow-move 1.5s linear infinite;
    }
  }
}
