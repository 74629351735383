@import "../../styles/common";
@import "../../styles/fonts";
@import "../../styles/mixins";
@import '../../module/animation/animation';
.introduction-section-2 {
  @include fade-in-from-bottom-paused;
  display: flex;
  flex-direction: column;
  max-width: $small-container;
  margin: 90px auto 0;
  gap: 30px;
  @include medium{
    margin: 130px auto 0;
    max-width: $medium-container;
    gap: 20px;
  }
  @include extra-large{
    max-width: none;
    flex-direction: row;
    margin-top: 236px;
    gap: 0;
  }


  .box-wrapper {
    position: relative;
    width: 100%;
    overflow: hidden;
    border-radius: 16px;
    padding: 50px 20px;
    transition: 300ms;

    @include medium {
      height: 530px;
      padding: 50px;

    }
    @include extra-large {
      width: 100%;
      height: 560px;
      border-radius: 0;
      filter: grayscale(100%);
    }

    &::before {
      content: "";
      opacity: 0.5;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      transition: 500ms;
    }

    &.first {
      &::before {
        background-image: url(../../assets/image/introduction-section-2/introductionBox1.png);
      }
      @include medium{
        margin-right: 20px;
      }
      @include extra-large{
        margin-right: 0;
      }
    }

    &.second {
      &::before {
        background-image: url(../../assets/image/introduction-section-2/introductionBox2.png);
      }
    }

    &.third {
      &::before {
        background-image: url(../../assets/image/introduction-section-2/introductionBox3.png);
      }
      @include medium{
        margin-right: 20px;
      }
      @include extra-large{
        margin-right: 0;
      }
    }

    &.fourth {
      &::before {
        background-image: url(../../assets/image/introduction-section-2/introductionBox4.png);
      }
    }

    .box-title {
      word-break: break-word;
      position: relative;
      z-index: 1;
      @include font-H4-bold;
      @include medium {
        // @include font-H2;
        @include font-H3-bold;
      }
      @include extra-large {
        @include font-H3;
      }
    }

    .box-plus {
      display: none;
      width: 38px;
      margin-top: 30px;
      object-fit: contain;
      opacity: 1;
      visibility: visible;
      @include extra-large {
        display: initial;
      }
    }

    .box-text {
      position: relative;
      z-index: 1;
      margin-top: 25px;
      visibility: visible;
      transition: 1000ms;
      @include font-H5;
      @include medium{
        margin-top: 30px;
        @include font-H4;
        line-height: 28px;
        word-break: keep-all;
      }
      @include extra-large{
        @include font-H4;
        opacity: 0;
        visibility: hidden;
      }
    }

    &:hover {
      @include extra-large {
        &::before {
          -webkit-filter: blur(5px);
          -moz-filter: blur(5px);
          -o-filter: blur(5px);
          -ms-filter: blur(5px);
          filter: blur(5px);
          
        }
        width: 125vw;
        filter: grayscale(0);
        .box-plus {
          display: none;
          opacity: 0;
          visibility: hidden;
        }
        .box-text {
          @include font-H4;
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}
