@import '../../styles/fonts';
@import "./../../styles/variable";
@import '../../module/animation/animation';

.service-section-2{
    margin-top: calc(222px - $header-height);
  @include fade-in-from-bottom-paused;

    @include extra-large{
    margin-top: calc(226px - $header-height);
    }
    &-title{
        @include font-H2;
        margin-bottom: 35px;
        padding-top: $header-height;
        @include medium{
            margin-bottom: 30px;
            @include font-H2
        }
        @include extra-large{
            @include font-H1
        }
    }
    &-sub-title{
        @include font-H4-bold;
        @include medium{
        @include font-H4-bold;
        }
        @include extra-large{
            @include font-H3;
        }
        color: #B0B8C1;
    }
    &-content-1{
        display: flex;
        flex-direction: column;
        margin-top: 83px;
        @include extra-large{
            flex-direction: row;
        }
        &-left{
            display: flex;
            flex-direction: column;
            justify-content: center;
            @include medium{
                flex-direction: row;
            }
            &-items{
                &-left{
                    @include medium{
                        margin-right: 32px;
                    }
                    &-item{
                        width: 350px !important;
                        height: 280px !important;
                        margin-bottom: 34px;
                        border: 1px solid #31363C;
                        border-radius: 8px;
                        background: #171B21;
                        @include medium{
                            width: 320px !important;
                            margin-bottom:25px ;
                        }
                        &-block{
                            width: 100%;
                            height: 100%;
                            padding: 24px 20px 0 20px;
                            @include medium{
                                padding: 24px 31px 0 24px;
                            }
                            @include extra-large{
                                padding: 32px 29px 0 24px;
                            }
                            &:last-child{
                            margin-bottom: 0px;
                        }
                        &-title{
                            margin-bottom: 4px;
                            @include font-H3
                        }
                        &-description{
                            color:#B1B1B5;
                            @include font-H5;
                        }
                        &-img{
                            width: 53px;
                            margin-bottom: 11px;
                            object-fit: contain;
                        }

                    }
                }}
                &-right{
                    @include medium{
                        margin-top: 51px;
                    }
                    &-item{
                        width: 350px !important;
                        height: 280px !important;
                        margin-bottom: 34px;
                        border: 1px solid #31363C;
                        border-radius: 8px;
                        background: #171B21;
                        @include medium{
                            width: 320px !important;
                            margin-bottom:25px ;
                        }
                        &-block{
                            width: 100%;
                            height: 100%;
                            padding: 24px 20px 0 20px;
                            @include medium{
                                padding: 24px 31px 0 24px;
                            }
                            @include extra-large{
                                padding: 32px 29px 0 24px;
                            }
                            &:last-child{
                            margin-bottom: 0px;
                        }
                        &-title{
                            margin-bottom: 4px;
                            @include font-H3
                        }
                        &-description{
                            color:#B1B1B5;
                            @include font-H5
                        }
                        &-img{
                            width: 53px;
                            margin-bottom: 11px;
                            object-fit: contain;
                        }
                    }
                }
                }
            }
    }
        &-right{
            margin-top: 100px;

            @include extra-large{
                margin-top: 113px;
                margin-left: 101px;
            }
            &-title{
                margin-bottom: 42px;
                @include font-H2;
                color:#1C67FE;
            }
            &-description{
                margin-bottom: 113px;
                @include font-H3-bold;
                color:#838990;
                line-height: 38px;
                @include medium{
                    @include font-H3-bold;
                    line-height: 34px;
                }
                @include extra-large{
                    @include font-H3;
                }
            }
            &-hilight-text{
                color:#fff;
            }
            &-button{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 209px;
                height: 50px;
                background: #253BFF;
                border-radius: 8px;
                cursor: pointer;
                transition: background-color 0.4s;
                &:hover{
                    background-color: #162DF3;
                }
            }
        }
    }
        &-content-2{
            display: flex;
            flex-direction: column;
            margin-top: 200px;
            @include extra-large{
                flex-direction:row ;
                justify-content: space-between;
            }
            &-item{
                width: 350px !important;
                height: 872px !important;
                margin-bottom: 30px;
                background-color: #171B21;
                border:1px solid #31363C;
                border-radius: 26px;
                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                @include medium{
                width: 794px !important;
                height: 772px !important;
                }
                @include extra-large{
                width: 585px !important;
                height: 872px !important;
                }
                &:last-child{
                height: 779px !important;
                margin-bottom: 0;
                @include medium{
                    width: 794px !important;
                    height: 793px !important;
                }
                @include extra-large{
                    width: 585px !important;
                    height: 872px !important;
                }
                }

                &-block{
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    padding: 40px 20px 0px 20px;
                    @include medium{
                        padding: 50px 50px 0 50px;
                    }
                    @include extra-large{
                        padding: 50px 70px 0px 70px;
                    }
                &-text{
                    @include font-H3-bold;
                    line-height: 38px;
                    @include medium{
                        @include font-H3;
                        line-height: 40px;
                    }
                    color: #838990;
                }
                &-img-1{
                    width: 310px;
                    margin-bottom: 52px;
                    object-fit: contain;
                    @include medium{
                        width: 384px;
                        margin-bottom: 50px;
                    }
                    @include extra-large{
                        margin-bottom: 50px;
                    }
                }
                &-img-2{
                    width: 310px;
                    margin-bottom: 62px;
                    object-fit: contain;
                    @include medium{
                        width: 556px;
                        margin-bottom: 61px;
                    }
                    @include extra-large{
                        width: 566px;
                        margin-bottom: 40px;
                    }
                }
                &-hilight-text{
                    color:#fff;
                }
            }
            }
        }
}
