@import '../../styles/fonts';
@import '../../module/animation/animation';

.news-item{
  padding: 0 0 20px;
  background-color: #171B21;
  border-radius: 26px;
  border: solid 1px #31363C;
  box-sizing: border-box;
  overflow: hidden;

  &-image{
    width: 100%;
    height: 210px;
    object-fit: cover;
    background-color: white;
  }

  &-content{
    padding: 0 19px;
  }

  &-category{
    width: fit-content;
    margin-top: 12px;;
    padding: 6px 13px;
    border-radius: 8px;
    @include font-H5;
    color: white;
    background-color: rgba($color: #EBF0F0, $alpha: 0.1);
  }

  &-title{
    display: -webkit-box;
    width: calc(100% );
    margin-top: 12px;
    @include font-H4;
    color: white;
    text-align: left;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-hashtag-container{
    display: flex;
    margin-top: 12px;
  }

  &-hashtag{
    margin-right: 10px;
    @include font-H5;
    color: #158EFF;

    &:last-child{
      margin-right: 0;
    }
  }
}
